import React from "react";  
import "./Navbar.css";  
import { FaBars } from "react-icons/fa";  
import "react-modern-drawer/dist/index.css";  
import Drawerreact from "./Drawerreact";  
import { Link } from "react-router-dom";  
import logo from '../Assets/vkrlogo-removebg-preview1.png'  

import { useGSAP } from "@gsap/react";  
import gsap from "gsap";  

const Navbar = (props) => {  
  const [isOpen, setIsOpen] = React.useState(false);  
  
  const toggleDrawer = () => {  
    setIsOpen((prevState) => !prevState);  
  };  

  const tl = gsap.timeline();  

  useGSAP(() => {  
    tl.from(".navbar-logo", {  
      y: 30,  
      opacity: 0,  
      duration: 0.5,  
      delay: 0.5,  
    });  

    tl.from(".list li", {  
      y: 40,  
      stagger: 0.1,  
      opacity: 0,  
    });  
  });  

  return (  
    <>  
      <div className="navbar">  
        <FaBars className="menu" onClick={toggleDrawer} />  
        <Link to="/">   
          <div className="navbar-logo">   
            <img src={logo} alt="logo" />   
          </div>   
        </Link>  
        <ul className="list">  
          <li>  
            <Link to="/">HOME</Link>  
          </li>  
          <li>  
            <Link to="/about">ABOUT US</Link>  
          </li>  
          <li>  
            <Link to="/team">MEET OUR TEAM</Link>  
          </li>  
          <li>  
            <Link to="/service">OUR SERVICES</Link>  
          </li>  
          <li>  
            <Link to="/contact">CONTACT US</Link>  
          </li>  
        </ul>  
      </div>  

      <div className="drawer">  
        <Drawerreact open={isOpen} onClose={toggleDrawer} />  
      </div>  
    </>  
  );  
};  

export default Navbar;