import React from "react";
import "./Service.css";
import NewsTikker from "./NewsTikker";
import Footer from "./Footer";

const Services = () => {
  const categories = [
    "FMCG Products",
    "Foods Products",
    "Beverages Products",

    "Dairy Products",
    "Agricultural Products",
    "Indian Spices",

    "Chemical Products",
    "Food Colours",
    "Textiles and Apparel",
    "Petroleum Products",
    "PALM OIL (Import from INDONESIA, MALAYSIA & THAILAND)",
    "Gems and Jewellery",
    "Pharmaceutical Products & Pharmaceutical Machineries",
    "Ingredients",
    "Restaurants (USA, CANADA, UNITED KINGDOM & EUROPEAN UNION)",
  ];

  return (
    <>
      <div className="services-container">
        <div className="services-header">
          <h2>OUR SERVICES</h2>
          <p className="service-tagline">
            Delivering Excellence in Global Sourcing, Global Procurement and
            International Business Development.
          </p>
        </div>
        <div className="service-sec2">
          <div>
            <ul className="service-list">
              <div>
                <li>GLOBAL SOURCING</li>
              </div>
              <div>
                {" "}
                <li> GLOBAL PROCUREMENT</li>
              </div>
              <div>
                <li> INTERNATIONAL BUSINESS DEVELOPMENT</li>
              </div>
            </ul>
          </div>
          <div className="services-description">
            <p>
              We collaborate with a diverse range of businesses, from small
              enterprises to multinational corporations, seeking to source goods
              from international markets for export or to support local
              operations.
            </p>
            <p>
              We also collaborate with a diverse range of businesses, spanning
              from small enterprises to multinational corporations, who are
              looking to avail of our International Business Development service
              to take their products around the world.
            </p>
            <p>
              We, “VKR UNIVERSAL CORPORATION’’, are covering the below
              categories at the moment in “Global Sourcing, Global Procurement
              and International Business Development’’.
            </p>
          </div>
        </div>

        <div className="category-grid">
          {categories.map((category, index) => (
            <div key={index} className="category-item">
              {category}
            </div>
          ))}
        </div>
      </div>
      <NewsTikker />
      <br />
      <Footer />
    </>
  );
};

export default Services;
